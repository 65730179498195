import React from 'react';
import styled from 'styled-components';
import { Footer } from '../footer';
import { Marginer } from '../marginer';
import { Navbar } from '../navbar';
import { InnerPageContainer, PageContainer } from '../pageContainer';
import { DeviceSize } from '../responsive';
import { SpecialistAd } from '../specialistAd';
import { Services } from './services';
import { TopSection } from './topSection';

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${DeviceSize.laptop}px;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  padding: 1em;

  @media screen and (max-width: ${DeviceSize.mobile}px) {
    padding: 5px;
  }
`;

export function Hh(props) {
  return (
    <PageContainer>
      <TopSection>
        <Navbar useTransparent />
      </TopSection>
      <InnerPageContainer>
        <Marginer direction="vertical" margin="2em" />
        <ContentContainer>
          <Services />
        </ContentContainer>
        <Marginer direction="vertical" margin="5em" />
        <SpecialistAd />
        <Marginer direction="vertical" margin="5em" />
      </InnerPageContainer>
      <Footer />
    </PageContainer>
  );
}
