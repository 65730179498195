import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Navbar } from './navbar';
import axios from 'axios';
import SendSharpIcon from '@material-ui/icons/SendSharp';
import { useState, useEffect } from 'react';
import Loader from './Loader';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { resetCameraImage, selectCameraImage } from './features/cameraSlice';
import './Preview.css';
import CancelIcon from '@material-ui/icons/Cancel';
import ShareIcon from '@material-ui/icons/Share';
import { v4 as uuid } from 'uuid';
import { db, storage } from './firebase';
import firebase from 'firebase';
import { selectUser } from './features/appSlice';

function Preview() {
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const cameraImage = useSelector(selectCameraImage);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!cameraImage) {
      history.replace('/');
    }
  }, [cameraImage, history]);

  const closePreview = () => {
    dispatch(resetCameraImage());
  };

  const sendPost = () => {
    axios
      .post(
        'https://dev.kyc.xane.ai/predictions/id',
        // {
        // 	image: this.props.location.state.img.split(",")[1],
        // 	variant_code: 'SSR4AL1',
        // },
        cameraImage.split(',')[1],
        {
          headers: {
            'Content-Type': 'text/plain;charset=utf-8',
            // 		'x-auth': 'b9d7e3b658ed46b0b66c1ffb0fbb04532460023701324455bb7044fb0f07548c',
            // 		// 'x-key': 'eecc2f7ce69040d9844d52bdb46cf167bdc878e8292d40aaaadea1f37db4e465',
            // 		'x-platform': 1,
            // 		'x-device-id': 'device',
            // 		'x-origin': 1,
            // 		'x-version': 1,
          },
        }
      )
      .then((response) => {
        // let filteredResponses = response.data.data.predictions[0].intent.replace(/_/g, ' ').toUpperCase();
        // let filteredResponses = response.data.data.interaction.predictions.splice(0, 3);
        let apiResponse = response.data;
        console.log(apiResponse);
        const { events } = apiResponse;
        setEventData(events);
        setLoading(false);
      });
    const id = uuid();
    const uploadTask = storage
      .ref(`posts/${id}`)
      .putString(cameraImage, 'data_url');

    uploadTask.on(
      'state_changed',
      null,
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref('posts')
          .child(id)
          .getDownloadURL()
          .then((url) => {
            db.collection('posts').add({
              imageUrl: url,

              read: false,

              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });
            history.replace('/scanResult');
          });
      }
    );
  };

  return (
    <div className="preview">
      <Navbar useTransparent />

      <ArrowBackIcon
        fontSize="large"
        onClick={closePreview}
        className="preview__close"
      />
      <p>{!loading ? <p eventData={eventData} /> : <Loader />}</p>

      <img src={cameraImage} alt="" />
      <div onClick={sendPost} className="preview__footer">
        <SendSharpIcon
          border="none"
          fill="green"
          color="white"
          fontSize="large"
          className="preview__sendIcon"
        />
      </div>
    </div>
  );
}

export default Preview;
