import React, { useEffect } from 'react';
import './App.css';
import WebcamCapture from './WebcamCapture';
import Preview from './Preview';
import styled from 'styled-components';
import { Hh } from './Hh';
import ViewScanResult from './ViewSanResult';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Results from './Results';
import ResultView from './ResultView';
import IndividualResult from './individualResult';
import { useDispatch, useSelector } from 'react-redux';

function App() {
  const dispatch = useDispatch();

  return (
    <div className="app">
      <Router>
        <Switch>
          <Route path="/results/view">
            <ResultView />
          </Route>
          <Route path="/results">
            <Results />
          </Route>
          <Route path="/individualResult">
            <IndividualResult />
          </Route>
          <Route path="/scanResult" exact component={ViewScanResult} />
          <Route path="/preview">
            <Preview />
          </Route>
          <Route path="/" exact component={Hh} />

          <Route exact path="/webCam">
            <WebcamCapture />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
