import { resetCameraImage, selectCameraImage } from './features/cameraSlice';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import './Result.css';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import ReactTimeago from 'react-timeago';
import { selectImage } from './features/appSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { db } from './firebase';
const useStyles = makeStyles({
  root: {
    minWidth: 245,
  },
  media: {
    height: 140,
  },
});
function Result({ id, username, timestamp, read, imageUrl, profilePic }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const cameraImage = useSelector(selectCameraImage);
  const classes = useStyles();

  const open = () => {
    dispatch(selectImage(imageUrl));
    history.push('/individualResult');
  };

  return (
    <Card onClick={open} className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={cameraImage}
          title="Contemplative Reptile"
        />
        <CardContent display="inline-block">
          <Typography gutterBottom variant="h5" component="h2">
            Lizard
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          <ShareOutlinedIcon />
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

export default Result;
