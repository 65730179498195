import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyAO4K2dlu7O_Ku19Hf6jAYNSAohRgMhavo',
  authDomain: 'kkkk-ee437.firebaseapp.com',
  projectId: 'kkkk-ee437',
  storageBucket: 'kkkk-ee437.appspot.com',
  messagingSenderId: '603527818936',
  appId: '1:603527818936:web:0af6c2716465128364ce4d',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };
