import { createSlice } from '@reduxjs/toolkit';

export const apiSlice = createSlice({
  name: 'api',
  initialState: {
    apiResponse: null,
  },
  reducers: {
    setApiResponse: (state, action) => {
      state.apiResponse = action.payload;
    },
  },
});

export const { setApiRsponse } = apiSlice.actions;

export const setApiResponse = (state) => state.api.apiResonse;

export default apiSlice.reducer;
