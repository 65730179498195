import { Avatar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './Results.css';
import HomeIcon from '@material-ui/icons/Home';
import styled from 'styled-components';
import { Navbar } from './navbar';
import { auth, db } from './firebase';
import Result from './Result';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from './features/appSlice';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { useHistory } from 'react-router-dom';
import { resetCameraImage } from './features/cameraSlice';

function Results() {
  const [posts, setPosts] = useState([]);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    db.collection('posts')
      .orderBy('timestamp', 'desc')
      .onSnapshot((snapshot) =>
        setPosts(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
  }, []);

  const takeSnap = () => {
    dispatch(resetCameraImage());
    history.push('/');
  };
  const backHome = () => {
    history.push('/');
  };

  return (
    <div className="results">
      <div className="results__header">
        <Navbar useTransparent />
      </div>
      <hr />
      <div className="results__posts">
        {posts.map(
          ({ id, data: { profilePic, username, timestamp, imageUrl } }) => (
            <Result
              key={id}
              id={id}
              username={username}
              timestamp={timestamp}
              imageUrl={imageUrl}
              profilePic={profilePic}
            />
          )
        )}
      </div>
    </div>
  );
}

export default Results;
