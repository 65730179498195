import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './ResultView.css';
import { setApiResponse } from './features/apiSlice';
import { selectSelectedImage } from './features/appSlice';
function ResultView() {
  const selectedImage = useSelector(selectSelectedImage);
  const history = useHistory();

  useEffect(() => {
    if (!selectedImage) {
      exit();
    }
  }, [selectedImage]);

  const exit = () => {};

  return (
    <div className="resultView">
      <div>{setApiResponse}</div>
      <img src={selectedImage} onClick={exit} alt="" />
    </div>
  );
}

export default ResultView;
