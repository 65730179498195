import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { BrandLogo } from '../brandLogo';
import { Button } from '../button';
import { Marginer } from '../marginer';
import { DeviceSize } from '../responsive';

import TopSectionBackgroundImg from '../images/landing-page.jpg';
import TheBestSpecialistsImg from '../images/Work only with the best.png';

const TopSectionContainer = styled.div`
  width: 100%;
  height: 800px;
  background: url(${TopSectionBackgroundImg}) no-repeat;
  background-position: 0px -150px;
  background-color: rgba(0, 0, 0, 0.9);

  background-size: cover;

  @media screen and (max-width: ${DeviceSize.mobile}px) {
    height: 700px;
    background-position: 0px 0px;
  }
`;

const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  border: none;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
`;

const TopSectionInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const StandoutImage = styled.div`
  width: 44em;
  height: 34em;

  img {
    width: 100%;
    height: 100%;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (max-width: ${DeviceSize.mobile}px) {
    align-items: center;
  }
`;

const SloganText = styled.h3`
  margin: 0;
  line-height: 1.4;
  color: #fff;
  font-weight: 500;
  font-size: 35px;

  @media screen and (max-width: ${DeviceSize.mobile}px) {
    font-size: 24px;
  }
`;

export function TopSection(props) {
  const { children } = props;

  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  return (
    <TopSectionContainer>
      <BackgroundFilter>
        {children}
        <TopSectionInnerContainer>
          <LogoContainer>
            <BrandLogo
              logoSize={isMobile ? 40 : 65}
              textSize={isMobile ? 35 : 55}
            />
            <Marginer direction="vertical" margin={8} />
            <SloganText>Find the right solution</SloganText>
            <SloganText>For your appliance</SloganText>
            <Marginer direction="vertical" margin={15} />
            <Link to="/webcam">
              <Button>Get Started</Button>
            </Link>
          </LogoContainer>
          {!isMobile && <StandoutImage></StandoutImage>}
        </TopSectionInnerContainer>
      </BackgroundFilter>
    </TopSectionContainer>
  );
}
