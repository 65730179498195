import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CancelIcon from '@material-ui/icons/Cancel';
import { Navbar } from './navbar';

import { resetCameraImage, selectCameraImage } from './features/cameraSlice';
function IndividualResult() {
  const dispatch = useDispatch();
  const history = useHistory();
  const cameraImage = useSelector(selectCameraImage);
  const closePreview = () => {
    history.push('./results');
  };

  return (
    <div>
      <Navbar useTransparent />

      <CancelIcon
        fontSize="large"
        onClick={closePreview}
        className="preview__close"
      />
      <img src={cameraImage} size="100%" alt="" />
    </div>
  );
}

export default IndividualResult;
